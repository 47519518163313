<script>

export default {
  data() {
    return {

    }
  },
  computed: {
    list() {
      let init = this.$store.state.init.initData;
      if (JSON.stringify(init) != '{}') {
        if (init.msg) {
        } else {
          this.$router.push('/goods');
        }
      }
      return init
    }
  },
}
</script>
<template>
  <div class="content">
    {{ list.msg }}
  </div>
</template>
<style lang="less" scoped>
.content {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
</style>
